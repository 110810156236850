<template>
  <header class="header" ref="header">
    <div :class="['burger', { _active: isBurgerActive }]" @click="toggleBurger">
      <span></span><span></span>
    </div>
    <div class="header__route">
      <i :class="['icon', icon]"></i>
      <span class="route__name">{{ pageName }}</span>
    </div>
    <ul class="header__account"></ul>
    <div class="header__user--id">
      <span class="header__user--id_text">ID</span>
      <span class="header__user--id_value">{{ user ? user.id : "-" }}</span>
    </div>
    <div
      @mouseenter="isManagerPopupOpen = true"
      @mouseleave="isManagerPopupOpen = false"
      :class="['header__manager', { opened: isManagerPopupOpen }]"
    >
      <div class="manager-toggler">
        Ваш менеджер
        <i class="icon-down-dir icon"></i>
      </div>
      <div class="manager-body" ref="manager_body">
        <div class="manager-body__title">
          <div class="img">
            <img src="img/logo.png" alt="NewsLab" />
            <div :class="['indicator', { online: isManagerOnline === true }]" />
          </div>
          <div class="manager-body__info">
            <h4 class="manager-name">Анна</h4>
            <p class="manager-time">
              С {{ startHour }}:00 до {{ endHour }}:00 по МСК
            </p>
          </div>
        </div>
        <p class="manager-body__text">
          Telegram:
          <a href="https://t.me/Anya_y" class="link" target="_blank">@Anya_y</a>
        </p>
        <p class="manager-body__text">
          Email:
          <a href="mailto:support@news-lab.club" class="link" target="_blank"
            >support@news-lab.club</a
          >
        </p>
        <a class="btn btn-primary" href="https://t.me/Anya_y" target="_blank"
          >Задать вопрос менеджеру</a
        >
      </div>
    </div>
    <div
      class="header__profile header__item notification"
      v-click-outside="() => (isNotificationsShowed = false)"
    >
      <div
        :class="['notification__header', { _active: isNotificationsShowed }]"
        @click="isNotificationsShowed = !isNotificationsShowed"
      >
        <img src="img/notification.svg" alt="" />
        <div class="indicator" v-if="isUnreadNotifications"></div>
      </div>
      <div class="notification__body" v-if="isNotificationsShowed">
        <p class="notification__body--title">Сообщения</p>
        <ul
          class="notification__body--list notification__list"
          v-if="notificationsList.length"
        >
          <li
            :class="['notification__list--item', { _read: item.read_at }]"
            v-for="item in notificationsList"
            :key="item.id"
            @click="readNotification(item.id)"
          >
            <div class="notification__list--item_img">
              <img src="img/danger.svg" alt="" />
            </div>
            <div class="notification__list--item_body">
              <div class="text">{{ item.title }}</div>
              <div class="date">{{ formatDate(item.created_at) }}</div>
            </div>
          </li>
        </ul>
        <p v-else class="no-data">Нет новых сообщений</p>
      </div>
    </div>
    <div
      @mouseover="isMenuOpened = true"
      @mouseleave="isMenuOpened = false"
      :class="{ _opened: isMenuOpened }"
      class="header__profile header__item"
    >
      <a class="header__profile--link" href="#"
        >{{ user ? user.name || user.email : "loading..."
        }}<i class="icon-down-dir icon"></i
      ></a>
      <ul class="account__settings">
        <div class="triangle-with-shadow"></div>
        <li class="account__settings--item">
          <i class="icon-user-icon icon"></i
          ><router-link
            class="account__settings--link"
            :to="{ name: 'profile', params: { mode: 'personal-data' } }"
            >Личные данные</router-link
          >
        </li>
        <li class="account__settings--item">
          <i class="icon-security-icon icon"></i
          ><router-link
            class="account__settings--link"
            :to="{ name: 'profile', params: { mode: 'security' } }"
            >Безопасность</router-link
          >
        </li>
        <li class="account__settings--item">
          <i class="icon-exit-icon icon"></i
          ><a
            @click.prevent="logOut"
            :class="{ disabled: loading }"
            href=""
            class="account__settings--link"
            >Выйти из профиля</a
          >
        </li>
      </ul>
    </div>
  </header>
  <NotificationPopup
    @close="close"
    :notification="notification"
    v-if="isNotificationPopupShowed"
    v-model:notification="notification"
  />
</template>

<script>
import axios from "axios";
import moment from "moment-timezone";
import NotificationPopup from "@/components/NotificationPopup.vue";
import { mapState } from "vuex";

export default {
  props: ["pageName", "icon"],
  data() {
    return {
      isBurgerActive: false,
      isMenuOpened: false,
      loading: false,
      isManagerPopupOpen: false,
      isManagerOnline: false,
      startHour: 10,
      endHour: 19,
      notificationsList: [],
      isNotificationsShowed: false,
      isNotificationPopupShowed: false,
      notification: null,
    };
  },
  emits: ["click:menu"],
  components: {
    NotificationPopup,
  },
  computed: {
    ...mapState(["user"]),
    isUnreadNotifications() {
      return this.notificationsList.find((item) => item.read_at === null);
    },
  },
  async created() {
    if (this.user) {
      this.getNotifications();
    }
  },
  methods: {
    async getNotifications() {
      const { data } = await axios.get("/api/notifications");
      this.notificationsList = data;
    },
    async readNotification(id) {
      try {
        this.notification = this.notificationsList.find(
          (item) => item.id === id
        );
        if (this.notification.read_at === null) {
          await axios.post("/api/notifications/update/" + id);
        }
        this.isNotificationPopupShowed = true;
        this.getNotifications();
      } catch (error) {
        const status = (error.response && error.response.status) || 500;
        this.$router.push(`/error/${status}`);
      }
    },
    toggleBurger() {
      this.isBurgerActive = !this.isBurgerActive;
      this.$emit("click:menu");
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    close() {
      this.isNotificationPopupShowed = false;
      this.notification = null;
    },
    async logOut() {
      this.loading = true;
      try {
        await axios.post("/api/log-out");
        this.$store.commit("setUser", null);
        location.href = process.env.VUE_APP_AFFILIATE_SITE_ENTRANCE;
      } catch (error) {
        const status = (error.response && error.response.status) || 500;
        this.$router.push(`/error/${status}`);
      }
      this.loading = false;
    },
  },
  mounted() {
    const hour = moment().tz("Europe/Moscow").hours();
    this.isManagerOnline = hour >= this.startHour && hour < this.endHour;

    if (window.innerWidth < 767) {
      this.$refs.manager_body.style.top = this.$refs.header.clientHeight + "px";
    }
  },
};
</script>

<style lang="scss">
.header {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.0526934);
  align-items: stretch;

  &__route {
    display: flex;
    align-items: center;
  }

  &__item {
    &.notification {
      padding-right: 20px;

      .indicator {
        position: absolute;
        background: #ff9c31;
        border: 1px solid #ffffff;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        right: 3px;
        top: 2px;
      }
    }
  }

  .notification {
    &__header {
      border-radius: 50%;
      padding: 5px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: color 0.2s ease, background-color 0.2s ease;

      &:hover,
      &._active {
        background-color: #f4f7fc;
        color: #1f8efa;
      }
    }
    &__body {
      position: absolute;
      width: 320px;
      top: 100%;
      right: -70px;
      margin-top: -10px;
      padding: 15px 0;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 3px 4px rgba(128, 141, 162, 0.35);
      z-index: 10;

      &--title {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #3f5171;
        margin-bottom: 8px;
        padding: 0 20px;
      }

      .no-data {
        font-size: 14px;
      }
    }

    &__list {
      &--item {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        transition: background-color 0.2s ease;
        padding: 0 20px;

        &:not(._read):hover {
          background-color: fade-out(#666, 0.9);
        }

        &._read {
          opacity: 0.7;
        }

        &_img {
          padding-top: 8px;
        }

        &_body {
          margin-left: 15px;
          padding: 8px 0;
          flex: 1;

          .text {
            font-size: 14px;
            line-height: 22px;
          }

          .date {
            font-size: 12px;
            line-height: 22px;
            color: #808da2;
          }
        }
      }

      &--item:not(:last-child) {
        .notification__list--item_body {
          border-bottom: 1px solid fade-out(#bfc6d0, 0.7);
        }
      }
    }
  }
}
.account__settings--link.disabled {
  pointer-events: none;
}

.header__user--id {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-left: auto;

  @media screen and (max-width: 540px) {
    padding: 0 10px;
  }

  &_text {
    font-size: 14px;
    line-height: 16px;
  }

  &_value {
    font-weight: 500;
    color: #1f8efa;
    margin-top: 5px;
  }
}

.header__manager {
  border-left: 1px solid #eceef1;

  &:hover {
    background: fade-out(#ff9c31, 0.85);
  }

  .manager-toggler {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    color: #ff9c31;
    padding: 0 25px;
    background: transparent;
    height: 100%;
    cursor: pointer;

    @media screen and (max-width: 540px) {
      padding: 0 10px;
    }
  }

  .manager-body {
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    border-top-right-radius: 0;
    padding: 30px 40px;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
    width: 360px;
    display: none;

    @media screen and (max-width: 767px) {
      width: calc(100vw - 40px);
      position: fixed;
      right: 50%;
      transform: translateX(50%);
      border-top-left-radius: 0;
    }

    &__title {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid fade-out(#657d95, 0.85);

      .img {
        flex: 0 0 60px;
        height: 60px;
        border-radius: 50%;
        background: #f8f8f8;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100%;
          margin-left: 4px;
        }

        .indicator {
          border: 3px solid #ffffff;
          box-sizing: border-box;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          position: absolute;
          top: 0;
          right: 0;
          background: red;

          &.online {
            background: #05c985;
          }
        }
      }
    }

    &__info {
      flex: 1;
      padding-left: 20px;

      .manager-name {
        font-weight: bold;
        font-size: 24px;
        color: #3f5171;
        margin-bottom: 5px;
      }

      .manager-time {
        font-size: 14px;
        color: #808da2;
      }
    }

    &__text {
      font-size: 14px;
      color: #808da2;
      margin-top: 15px;
    }

    .btn {
      margin-top: 25px;
    }
  }

  &.opened {
    .manager-body {
      display: block;
    }
  }
}
</style>
