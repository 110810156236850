import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-79114c4a")
const _hoisted_1 = {
  key: 0,
  class: "form__inner"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "sender" }
const _hoisted_4 = { class: "date" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_popup = _resolveComponent("popup")!

  return (_openBlock(), _createBlock(_component_popup, {
    "html-id": "notificationPopup",
    onClose: _ctx.close
  }, {
    default: _withCtx(() => [
      (_ctx.notification)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.notification.title), 1),
            _createElementVNode("div", {
              innerHTML: _ctx.notification.body
            }, null, 8, _hoisted_2),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.notification.sender_name), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.formatDate(_ctx.notification.created_at)), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onClose"]))
}