
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "Notifier",
  computed: {
    ...mapState({
      value: "notification",
    }),
  },
  watch: {
    value(newValue) {
      if (newValue && newValue.autoClose) {
        setTimeout(() => this.$store.commit("setNotification", null), 3000);
      }
    },
  },
  methods: {
    close() {
      this.$store.commit("setNotification", null);
    },
  },
});
