
import { defineComponent } from "vue";
import Popup from "@/components/Popup.vue";
import moment from "moment-timezone";

export default defineComponent({
  props: {
    notification: Object,
  },
  components: {
    Popup,
  },
  emits: ["close"],
  methods: {
    formatDate(date: string) {
      return moment(date).format("DD.MM.YYYY");
    },
    close() {
      this.$emit("close");
    },
  },
});
