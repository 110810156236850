
import { defineComponent, PropType } from "@vue/runtime-core";
import AppHeader from "./AppHeader.vue";
import Notifier from "./Notifier.vue";
import { mapState } from "vuex";
import axios from "axios";

export default defineComponent({
  components: { AppHeader, Notifier },
  props: {
    name: String as PropType<string>,
    icon: String as PropType<string>,
    htmlClass: {
      type: String as PropType<string | undefined>,
      required: false,
    },
  },
  data() {
    return { isMenuOpened: false };
  },
  computed: {
    ...mapState(["user"]),
  },
  async created() {
    const response = await axios.get("/api/get-profile");
    if (response.data) {
      this.$store.commit("setUser", response.data);
    } else {
      location.href = process.env.VUE_APP_AFFILIATE_SITE_ENTRANCE;
    }
  },
  watch: {
    isMenuOpened() {
      document.querySelector("html")?.classList.toggle("sidebar-opened");
    },
  },
  mounted() {
    const html = document.querySelector("html");
    const links = document.querySelectorAll(".sidebar__link");

    links.forEach((link) =>
      link.addEventListener("click", () =>
        html?.classList.remove("sidebar-opened")
      )
    );
  },
});
