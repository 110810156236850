<template>
  <div :class="['popup', className]" :id="htmlId">
    <div class="icon__close" data-fancybox-close>
      <span></span><span></span>
    </div>
    <div class="popup__inner">
      <h2 class="popup__title">{{ title }}</h2>
      <slot />
    </div>
  </div>
</template>

<script>
import "@fancyapps/fancybox";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    htmlId: String,
    title: String,
    hasConfirm: { type: Boolean, default: false },
    className: String,
  },
  emits: ["close"],
  mounted() {
    $.fancybox.open({
      src: "#" + this.htmlId,
      touch: false,
      autoFocus: false,
      afterClose: () => this.$emit("close"),
      beforeClose: (instance, current, event) => {
        if (this.hasConfirm && event) {
          const answer = confirm(
            "Вы уверены, что хотите закрыть окно? В этом случае все внесенные вами изменения не сохранятся."
          );
          if (answer) {
            return true;
          }
          return false;
        }
      },
    });
  },
  beforeUnmount() {
    $.fancybox.close();
  },
});
</script>

<style lang="scss">
.popup {
  .actions {
    margin-top: 30px;
    display: flex;
    gap: 15px;
  }
  .actions > * {
    flex: 0 1 50%;
  }
}

.campaign_popup {
  width: 630px;
  max-width: 100%;
  padding: 40px 20px;

  .popup__form {
    width: 100%;
  }
}

#guideKeitaro.popup {
  @media screen and (max-width: 767px){
    width: 100%;
  }
}
</style>
